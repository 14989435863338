import React from "react"

const Calendly = () => {
  return (
    <div style={{ height: "700px" }}>
      <iframe
        src="https://calendly.com/insidehero/15min"
        width="100%"
        height="100%"
        frameBorder="0"
      ></iframe>
    </div>
  )
}

export default Calendly
