import React from "react"
import { Link } from "gatsby"


const Maincta = () => {

    return(

        <Link to="/demo"><button className="btn btn-main">BOOK DEMO</button></Link>
    )
}

export default Maincta
