import { Link } from "gatsby"
import React, { useState } from "react"
import logo from "../images/logo.svg"
import Maincta from "./Maincta"
import Navbar from "./Navbar"

const Header = () => {
  const [isOpenMenu, setIsOpenMenu] = useState(false)
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false)

  return (
    <div>
      <header>
        <Navbar>
          <div className="container">
            <div className="header-cont">
              <div className="logo">
                <Link to="/">
                  <img src={logo} alt="logo" />
                </Link>
              </div>

              <nav>
                <ul className="nav_list">
                  <li>
                    <a>Solutions</a>

                    <div className="sub-menu-single">
                      <ul>
                        <li>
                          <Link to="/small-business">Small Business</Link>
                        </li>

                        <li>
                          <Link to="/enterprise">Enterprise</Link>
                        </li>
                      </ul>
                    </div>
                  </li>

                  <li>
                    <a>Product</a>

                    <div className="sub-menu-single">
                      <ul>
                        <li>
                          <Link to="/reviews">Reviews</Link>
                        </li>

                        <li>
                          <Link to="/referrals">Referrals</Link>
                        </li>
                        <li>
                          <Link to="/inbox">Inbox</Link>
                        </li>
                      </ul>
                    </div>
                  </li>

                  <li>
                    <Link to="/pricing">Pricing</Link>
                  </li>
                  <li>
                    <a href="https://app.insidehero.io">Login</a>
                  </li>
                </ul>
              </nav>

              <div className="btn-menu">
                <Maincta />
              </div>

              <div
                className={`menu-toggle-icon ${isOpenMenu ? "opened" : ""}`}
                onClick={() => setIsOpenMenu(!isOpenMenu)}
              ></div>
            </div>
          </div>

          <div className={`mobile-nav ${isOpenMenu ? "open" : ""}`}>
            <nav>
              <ul className="mobile_nav_list">
                <li>
                  <span
                    onClick={() => setIsSubMenuOpen(!isSubMenuOpen)}
                    className="nav_list_pointer"
                  >
                    Solutions
                  </span>
                  {isSubMenuOpen && (
                    <div className="sub-menu-single">
                      <ul>
                        <li>
                          <Link to="/small-business">Small Business</Link>
                        </li>
                        <li>
                          <Link to="/enterprise">Enterprise</Link>
                        </li>
                      </ul>
                    </div>
                  )}
                </li>

                <li>
                  <span
                    onClick={() => setIsSubMenuOpen(!isSubMenuOpen)}
                    className="nav_list_pointer"
                  >
                    Product
                  </span>
                  {isSubMenuOpen && (
                    <div className="sub-menu-single">
                      <ul>
                        <li>
                          <Link to="/reviews">Reviews</Link>
                        </li>
                        <li>
                          <Link to="/referrals">Referrals</Link>
                        </li>
                        <li>
                          <Link to="/inbox">Inbox</Link>
                        </li>
                      </ul>
                    </div>
                  )}
                </li>

                <li>
                  <Link to="/pricing">Pricing</Link>
                </li>

                <li>
                  <a href="https://app.insidehero.io">Login</a>
                </li>
              </ul>
            </nav>

            <div
              className="btn-menu"
              id="myLinks"
              onClick={() => setIsOpenMenu(!isOpenMenu)}
            >
              <Maincta />
            </div>
          </div>
        </Navbar>
      </header>
    </div>
  )
}

export default Header
