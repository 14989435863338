import React, { useState, useEffect } from "react"
import "../css/style.scss"

function Navbar(props) {
  const [navbar, setNavbar] = useState(false)

  useEffect(() => {
    const changeBackground = () => {
      if (window.scrollY >= 20) {
        setNavbar(true)
      } else {
        setNavbar(false)
      }
    }

    window.addEventListener("scroll", changeBackground)
  }, [])

  return (
    <div className={navbar ? "site-header fixed" : "site-header"}>
      {props.children}
    </div>
  )
}

export default Navbar
