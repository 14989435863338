import { Link } from "gatsby"
import React from "react"
import logo from "../images/logo.svg"

const currYear = new Date().getFullYear()

const Footer = () => {
  return (
    <div className="footer-bg">
      <div className="container">
        <footer>
          <div className="footer-items">
            <div className="footer-items-col-1">
              <p>COMPANY</p>
              <p>
                <Link to="/about-us">About us</Link>
              </p>
              <p>
                <Link to="/contact">Contact us</Link>
              </p>
              <p>
                <Link to="/terms">Privacy & terms</Link>
              </p>
            </div>

            <div className="footer-items-col-2">
              <p>SOLUTIONS</p>
              <p>
                <Link to="/small-business">Small Business</Link>
              </p>
              <p>
                <Link to="/enterprise">Enterprise</Link>
              </p>
              <p>
                <Link to="/pricing">Pricing</Link>
              </p>
              <p>
                <a href="">Request Demo</a>
              </p>
            </div>

            <div className="footer-items-col-3">
              <p>PRODUCT</p>
              <p>
                <Link to="/reviews">Reviews</Link>
              </p>
              <p>
                <Link to="/referrals">Referrals</Link>
              </p>
              <p>
                <Link to="/inbox">Inbox</Link>
              </p>
            </div>

            <div className="footer-items-col-4">
              <p>RESOURCES</p>
              {/* <p>
              <Link to="/ebooks">Ebooks</Link>
            </p> */}
              <p>
                <Link to="/contact">Support</Link>
              </p>
              {/* <p>
              <a href="">Scan your reviews</a>
            </p> */}
            </div>

            <div className="footer-items-col-5">
              <p>HEADQUARTERS</p>
              <p>Swan street, Manchester, M4 5JW, UK</p>
            </div>
          </div>

          <div className="footer-bottom">
            <img src={logo} alt="" />
            <p>InsideHero {currYear}</p>
          </div>
        </footer>
      </div>
    </div>
  )
}

export default Footer
