import React from "react"
import Layout from "../components/Layout"
import { Link } from "gatsby"
import logo from "../images/logo.svg"
import { PopupWidget } from "react-calendly"
import Calendly from "./calendly"

const demo = () => {
  return (
    <div className="demoPage">
      <div className="container">
        <section className="demoPage-nav">
          <div className="logo">
            <Link to="/">
              <img src={logo} alt="logo" />
            </Link>
          </div>
          {/* <a href="">
            <button className="btn-demoPage"></button>
          </a> */}
        </section>
      </div>

      <div className="container">
        <section className="demoPage-container">
          <h1>Book a time with InsideHero</h1>
          <Calendly />
        </section>
      </div>
    </div>
  )
}

export default demo
